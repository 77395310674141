<template>
  <div style="overflow-y:hidden;overflow-x: hidden">
    <router-view/>
  </div>
</template>
<script>
  export default {
    name: "App"
  }
</script>
<style>
  .container{margin: auto auto 80px;width: 1080px;}
  .container a{color: #000; text-decoration: none;}
  .container a:hover{color: #000; text-decoration: none;}
  .footer{background: #000;color: #fff; height: 280px;}
  .footer img{height: 50px; margin:5px}
  .bg-primary{background: #409eff;}
  .p-5{padding: 20px; text-align: center;}
  .mt-10{margin-top: 10px;}
</style>