import { createRouter, createWebHistory } from 'vue-router'
import { ElMessage } from "element-plus"

const routes = [{
    path: '/login', name: 'Login', component: () => import("@/views/Login")
}, {
    path: '/register', name: 'Register', component: () => import("@/views/Register")
}, //管理端
{
    path: '/manage',
    name: 'Manage',
    redirect: "/manage/chart",
    component: () => import('@/views/manage/Layout'),
    children: [{
        path: 'chart', name: 'Manage-Chart', component: () => import("@/views/manage/home/Chart")
    },  {
        path: 'cards/lists', name: 'Manage-cards', component: () => import("@/views/manage/cards/lists")
    }, {
        path: 'users/lists', name: 'Manage-User', component: () => import("@/views/manage/users/lists")
    },  {
        path: 'password', name: 'Manage-Password', component: () => import("@/views/manage/person/Password")
    },  {
        path: 'Person', name: 'Manage-Person', component: () => import("@/views/manage/person/Person")
    }]
}]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes
})

router.beforeEach((to, from, next) => {//配置路由守卫
    console.log(to)
    if(to.path == '/'){
        next({ path: '/login', query: { to: to.path } })
    }
    let user = JSON.parse(sessionStorage.getItem("user") || "{}")
    if (!user.id) {
        
        //        next({ path: '/login', query: { to: to.path } })
        // if (to.path.startsWith('/news') || to.path.startsWith('/review') || to.path.startsWith('/newsdetail') || to.path.startsWith('/reviewdetail')) {
        //    if (from.path.startsWith('/login')) {
        //        next({ path: '/' })
        //    } else {
        //        ElMessage.error('权限不够，请先登录')
        //        next({ path: '/login', query: { to: to.path } })
        //    }
        //    return
        //}
    }
    next()
});

router.afterEach((to, from) => {
    document.title = to.query.title || to.meta.title || '卡券管理系统'
});

export default router
