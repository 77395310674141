import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/global.css'
import config from './utils/config.js'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import ElementPlus from 'element-plus'
import {ElMessage} from "element-plus"
import 'element-plus/dist/index.css'
import '@/assets/icon/iconfont.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import DateTimeFilters from './utils/function.js';



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.config.globalProperties.$ = config;
app.use(router).use(ElementPlus,{locale: zhCn, size:"large"}).provide('$message', ElMessage).mount('#app')
app.use(DateTimeFilters);